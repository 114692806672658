import * as React from "react"
import './index.css'
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import logo from '../../images/big-logo.svg'

const SuccessPage = () => (
  <Layout>
    <SEO title="Success" />
    <div className='page-not-found-container'>
      <img src={logo} alt='Kevin Rich is an accountant in Pasdena Maryland' />
      <h1>Form Submitted</h1>
      <p>Thank you for getting in touch. I'll reach out to you as soon as possible regarding your inquiry</p>
    </div>
  </Layout>
)

export default SuccessPage